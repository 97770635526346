import { forwardRef } from 'react';
import logo from '../../Assets/logo-dark.svg'

const ComponentToPrint = forwardRef(( props, ref ) => {
    return (
        <div className="container" style={{
            color: 'blue'
            }
        }>
            <div ref={ref} className="container-fluid pt-5 h-75">
                <img src={logo}/>
                <h2 className="ms-3 text-primary pt-4">{props.data.utiid}</h2>

                <div className="row">
                    <div className="col-6">
                        <ul className="list-group">
                            <li className="list-group-item fw-bold">Container information</li>
                            <li className="list-group-item">Status: {props.data.statusOpis}</li>
                            <li className="list-group-item">Registration: {props.data.registracija}</li>
                            <li className="list-group-item">Booking number: {props.data.bookingst}</li>
                            <li className="list-group-item">Date of departure: {props.data.datumOdhoda}</li>
                            <li className="list-group-item">Time of departure: {props.data.uraOdhoda}</li>
                            <li className="list-group-item">Bill of Lading: {props.data.billoflading}</li>
                            <li className="list-group-item">Current Date: {props.data.trenutniDatum}</li>
                            <li className="list-group-item">Ship Owner: {props.data.ladjar}</li>
                            <li className="list-group-item">Type of Container: {props.data.tipKontejnerja}</li>
                            <li className="list-group-item">Nevarno
                                blago: {props.data.nevarnoBlago === 0 ? 'No' : 'Yes'}</li>
                            <li className="list-group-item">Weight of Material: {props.data.masaBlaga}</li>
                            <li className="list-group-item">Gross: {props.data.bruto}</li>
                            <li className="list-group-item">Tovorni list: {props.data.tovorniList}</li>
                            <li className="list-group-item">un: {props.data.un}</li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <li className="list-group-item fw-bold">Truck information</li>
                                <li className="list-group-item">Date of Arrival: {props.data.datumPrihoda}</li>
                                <li className="list-group-item">Time of Arrival: {props.data.uraPrihoda}</li>
                                <li className="list-group-item">Departure Terminal: {props.data.odhodniTerminal}</li>
                                <li className="list-group-item">Arrival Terminal: {props.data.prihodniTerminal}</li>
                                <li className="list-group-item">Starting Terminal: {props.data.zacetniTerminal}</li>
                                <li className="list-group-item">Ending Terminal: {props.data.koncniTerminal}</li>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <li className="list-group-item fw-bold">Description</li>
                                <li className="list-group-item">{props.data.blago}</li>
                            </li>
                        </ul>

                    </div>

                </div>
            </div>
        </div>

    )
});

export default ComponentToPrint;