import Axios from "axios";
import { useState } from "react"
import {json, Link, useNavigate} from "react-router-dom";
import './Login.scss';
import backgroundImage from './login_background-min.png'
import { Alert } from "react-bootstrap";
import logo from '../../Assets/logo_fixed.svg'
import Store from "store";
import Swal from "sweetalert2";

export function EnterPasswordResetToken() {
    const navigate = useNavigate();

    const [loginForm, setLoginForm] = useState({
        email: "",
        password: "",
        token: "",
    });

    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [wrongCredentialsMessage, setWrongCredentialsMessage] = useState('');

    const changeHandler = (e) => {
        setLoginForm((prevState) => {
            return {...prevState, [e.target.name]: e.target.value}
        })
    }

    const requestLogin = () => {
        Swal.showLoading();
        Axios.post(process.env.REACT_APP_API_URL + '/submitNewPassword', {
            email: loginForm.email,
            password: loginForm.password,
            token: loginForm.token
        })
            .then(res => {
                if(res.status === 200) {
                    navigate('/login');
                    Swal.close();
                }
            })
            .catch(res => {
                if(res.response.status === 401) {
                    console.log('unauthorized');
                    setWrongCredentialsMessage('Wrong username or password!');
                    setWrongCredentials(true);
                    Swal.close();
                } else if (res.response.status === 422) {
                    console.log('422');
                    setWrongCredentialsMessage('Wrong username or password!');
                    setWrongCredentials(true);
                    Swal.close();
                } else if (res.response.status === 500) {
                    console.log('system error');
                    Swal.close();
                }
            })
    }

    return (
        <div style={{
            backgroundImage: `url(${backgroundImage})`,
        }}>
            <div className="container vh-100">

                <nav className="navbar navbar-light">
                    <img className="navbar-brand site-logo px-0" src={logo}/>
                </nav>

                <div className="row h-75">

                    <div className="col-12 col-md-6 col-lg-4">

                        <div className="login-wrapper h-100">

                            <div className="login ">
                                <h1 className="text-white bold login-title">Reset password.</h1>
                                <div className="email d-flex flex-column">
                                    <span>Username (email)</span>
                                    <input type="text" name="email" onChange={changeHandler}
                                           className="form-control border-0"/>
                                </div>

                                <div className="email d-flex flex-column">
                                    <span>Password</span>
                                    <input type="password" name="password" onChange={changeHandler}
                                           className="form-control border-0"/>
                                </div>

                                <div className="email d-flex flex-column">
                                    <span>Token</span>
                                    <input type="text" name="token" onChange={changeHandler}
                                           className="form-control border-0"/>
                                </div>

                                {wrongCredentials && (
                                    <Alert className="mt-3" variant="danger">
                                        {wrongCredentialsMessage}
                                    </Alert>
                                )}
                                <div
                                    className="lost-wrapper d-flex flex-row justify-content-between align-items-center mt-3">
                                    <button className="login-btn" onClick={requestLogin}>Reset password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}