
import './App.scss';
import {Login} from "./Components/Login/Login";
  import {ResetPassword} from "./Components/Login/ResetPassword";
import {Dashboard} from "./Components/Dashboard/Dashboard";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ProtectedRoutes from "./Utils/ProtectedRoutes";
import { Settings } from './Components/Settings/Settings';
  import {EnterPasswordResetToken} from "./Components/Login/EnterPasswordResetToken";

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/login" element={<Login />}/>
            <Route path="/resetpassword" element={<ResetPassword />}/>
            <Route path="/enterPasswordResetToken" element={<EnterPasswordResetToken />}/>
            <Route element={<ProtectedRoutes />}>
                <Route path='/' element={<Dashboard />}/>
                <Route path="/dashboard" element={<Dashboard />}/>
                <Route path="settings" element={<Settings />}/>
            </Route>
        </Routes>

    </BrowserRouter>
  );
}

export default App;
