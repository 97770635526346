import { forwardRef } from 'react';
import logo from '../../Assets/logo-dark.svg'
import {forEach} from "react-bootstrap/ElementChildren";

const ComponentToPrintAll = forwardRef(( props, ref ) => {
    return (
        <div className={"container-fluid"} ref={ref}>
            {props.data.map((el, index) => (
                <div className="container-fluid pt-5 h-75" key={index}>
                    <img src={logo}/>
                    <h2 className="ms-3 text-primary pt-4">{el.utiid}</h2>

                    <div className="row">
                        <div className="col-6">
                            <ul className="list-group">
                                <li className="list-group-item fw-bold">Container information</li>
                                <li className="list-group-item">Status: {el.statusOpis}</li>
                                <li className="list-group-item">Registration: {el.registracija}</li>
                                <li className="list-group-item">Booking number: {el.bookingst}</li>
                                <li className="list-group-item">Date of departure: {el.datumOdhoda}</li>
                                <li className="list-group-item">Time of departure: {el.uraOdhoda}</li>
                                <li className="list-group-item">Bill of Lading: {el.billoflading}</li>
                                <li className="list-group-item">Current Date: {el.trenutniDatum}</li>
                                <li className="list-group-item">Ship Owner: {el.ladjar}</li>
                                <li className="list-group-item">Type of Container: {el.tipKontejnerja}</li>
                                <li className="list-group-item">Nevarno
                                    blago: {el.nevarnoBlago === 0 ? 'No' : 'Yes'}</li>
                                <li className="list-group-item">Weight of Material: {el.masaBlaga}</li>
                                <li className="list-group-item">Gross: {el.bruto}</li>
                                <li className="list-group-item">Tovorni list: {el.tovorniList}</li>
                                <li className="list-group-item">un: {el.un}</li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <li className="list-group-item fw-bold">Truck information</li>
                                    <li className="list-group-item">Date of Arrival: {el.datumPrihoda}</li>
                                    <li className="list-group-item">Time of Arrival: {el.uraPrihoda}</li>
                                    <li className="list-group-item">Departure
                                        Terminal: {el.odhodniTerminal}</li>
                                    <li className="list-group-item">Arrival Terminal: {el.prihodniTerminal}</li>
                                    <li className="list-group-item">Starting Terminal: {el.zacetniTerminal}</li>
                                    <li className="list-group-item">Ending Terminal: {el.koncniTerminal}</li>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <li className="list-group-item fw-bold">Description</li>
                                    <li className="list-group-item">{el.blago}</li>
                                </li>
                            </ul>

                        </div>

                    </div>
                    <p
                        key={index}
                        style={{breakAfter: "page"}}
                    />
                </div>
                ))}

        </div>
    );
});

export default ComponentToPrintAll;