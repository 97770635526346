import "./Dashboard.scss";
import {useEffect, useRef, useState} from "react";
import {getToken} from "../../Utils/AuthHelper";
import Axios from "axios";
import Swal from "sweetalert2";
import DataTable, {createTheme} from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import searchIcon from "../../Assets/search_icon.svg"
import containerInfoIcon from "../../Assets/container_info_icon.svg"
import descriptionIcon from "../../Assets/description_icon.svg"
import trainInfoIcon from "../../Assets/train_info.svg"
import {getThemeMode} from "../../Utils/ThemeModeHelper";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import ComponentToPrintAll from "./ComponentToPrintAll";
import {BsArrowDownUp, BsChevronDown, BsChevronUp, BsSortDown, BsSortUp} from "react-icons/bs";
import {BiSort} from "react-icons/bi";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";

export function Dashboard() {
    const [tableData, setTableData] = useState([]);
    const [datumOd, setDatumOd] = useState("");
    const [datumDo, setDatumDo] = useState("");
    const [searchRegistration, setSearchRegistration] = useState("")
    const componentRef = useRef();
    const printAllContentRef = useRef();

    const ExpandableRowComponent = ({data}) => (
        <div className={`container expandable-row p-4 container-info ${getThemeMode() === 'dark' ? 'bg-dark' : ''}`} data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <img className="me-2 hidden-print" src={containerInfoIcon} width="22px"/>
                        <h6 className=" d-inline align-items-center">Container information</h6>
                    </div>
                    <div className="col-xl-6 hidden-print">
                        <img className="me-2" src={trainInfoIcon} width="18px"/>
                        <h6 className=" d-inline align-items-center">Train information</h6>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-xl-12 p-0">
                    <div className="card bg-transparent border-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"><span
                                            className=" text-primary">Status ID</span> <span
                                            className="fw-bold">{data.statusOpis}</span></li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Registration</span> <span
                                            className="fw-bold">{data.registracija}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Booking number</span> <span
                                            className="fw-bold">{data.bookingst}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Tip of Container</span> <span
                                            className="fw-bold">{data.tipKontejnerja}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Date of Departure</span> <span
                                            className="fw-bold">{data.datumOdhoda}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Time of Departure</span> <span
                                            className="fw-bold">{data.uraOdhoda}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Date of Arrival</span> <span
                                            className="fw-bold">{data.datumPrihoda}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Time of Arrival</span> <span
                                            className="fw-bold">{data.uraPrihoda}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Tovorni list</span> <span
                                            className="fw-bold">{data.tovorniList}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">un</span> <span
                                            className="fw-bold">{data.un}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Dangerous</span> <span
                                            className="fw-bold">{data.nevarnoBlago === 0 ? "No" : "Yes"}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Weight of Material</span> <span
                                            className="fw-bold">{data.masaBlaga}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Bruto</span> <span
                                            className="fw-bold">{data.bruto}</span>
                                        </li>

                                    </ul>
                                    <div className="row mt-2">
                                        <div className="col-xl-12">
                                            <div className="card border-0 ">
                                                <div className="card-body">
                                                    <div className="card border-0 ">
                                                        <div className="card-body bg-light-gray rounded">
                                                            <div className="container ps-0 pb-2">
                                                                <img className="me-2" src={descriptionIcon}
                                                                     width="22px"/>
                                                                <h6 className=" d-inline align-items-center">Description</h6>
                                                            </div>
                                                            {data.blago}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"><span
                                            className="text-primary">Bill of Lading</span> <span
                                            className="fw-bold">{data.billOfLading}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Current date</span> <span
                                            className="fw-bold">{data.trenutniDatum}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Ship owner</span> <span
                                            className="fw-bold">{data.ladjar}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Train Wagon</span> <span
                                            className="fw-bold">{data.vagonid}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Departure Terminal</span> <span
                                            className="fw-bold">{data.odhodniTerminal}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Arrival Terminal</span> <span
                                            className="fw-bold">{data.prihodniTerminal}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Starting Terminal </span> <span
                                            className="fw-bold">{data.zacetniTerminal}</span>
                                        </li>
                                        <li className="list-group-item"><span
                                            className="text-primary">Ending Terminal</span> <span
                                            className="fw-bold">{data.koncniTerminal}</span>
                                        </li>



                                    </ul>
                                    <div className="col-xl-12">
                                        <div className="card mt-2 border-0">
                                            <div className="card-body">
                                                <div>
                                                    <ReactToPrint
                                                        trigger={() => <button
                                                            className="float-end btn btn-outline-primary hidden-print"
                                                            data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}>Print now
                                                        </button>}
                                                        content={() => componentRef.current}
                                                    />

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none">
                                <ComponentToPrint ref={componentRef}
                                                  data={data}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">

                </div>
            </div>
        </div>
    );


    useEffect(() => {
        console.log('Dashboard loaded');
        console.log(JSON.parse(getToken()));

        Swal.showLoading();

        fetchContainers(false);
    }, []);

    function fetchContainers(isArchive) {
        Axios.get(process.env.REACT_APP_API_URL + `/getallcontainers?archive=${isArchive}`, {
            headers: {
                Authorization: "Bearer " + JSON.parse(getToken())
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setTableData(res.data);
                    Swal.close();
                }
            })
            .catch(res => {
                if (res.status != null) {
                    if (res.response.status === 401) {
                        console.log('system error');
                    } else if (res.response.status === 422) {
                        console.log('system error');
                    } else if (res.response.status === 500) {
                        console.log('system error');
                    }
                } else {
                    Swal.close();
                }
            })
    }

    createTheme('solarized', {
        text: {
            primary: 'white',
            secondary: 'white',
        },
        background: {
            default: 'transparent',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const columns = [

        {
            name: 'Registration',
            selector: row => row.registracija,
            sortable: true,
            grow: 1
        },
        {
            name: 'Departure',
            selector: row => row.datumOdhoda,
            sortable: true
        },
        {
            name: 'Arrival',
            selector: row => row.datumPrihoda,
            sortable: true
        },
        {
            name: 'Departure Terminal',
            selector: row => row.odhodniTerminal,
            sortable: true,
            grow: 2
        },
        {
            name: 'Arrival Terminal',
            selector: row => row.prihodniTerminal,
            sortable: true,
            grow: 2
        },
        {
            name: 'Status',
            selector: row => row.statusOpis,
            sortable: true,
            grow: 2
        },
    ];

    const setSearch = (e) => {
        console.log(e.target.value);
        setSearchRegistration(e.target.value);
        /*setSearchRegistration((prevState) => {
            return {...prevState, [e.target.name]: e.target.value}
        })*/
    }

    const parseGMTDateToDDMMYYYY = (dateString) => {
        const date = new Date(dateString); // Parse the GMT date string

        // Extract day, month, and year
        const day = String(date.getDate()).padStart(2, '0'); // Use getUTCDate() for the day in UTC
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Use getUTCMonth() + 1 for month in UTC
        const year = date.getUTCFullYear(); // Use getUTCFullYear() for the year in UTC

        // Return formatted date as "dd MM YYYY"
        return `${day}-${month}-${year}`;
    };

    function resetFilters() {
        Swal.showLoading();

        fetchContainers(false);

        setSearchRegistration("");
        setDatumOd("");
        setDatumDo("");
    }

    function getArchived() {
        Swal.showLoading();
        fetchContainers(true);
        Swal.fire("Archive loaded");
    }

    function printContent() {
        window.print()
    }

    return (
        <div className={`container-fluid min-vh-100 ${getThemeMode() === 'dark' ? 'bg-dark' : ''}`}>

            <div className="row">
                <div className="search-container hidden-print d-flex flex-row justify-content-between align-items-center" data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}>
                    <div className="col-xl-2 col-sm-1">

                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="bg-white rounded-5 search-form-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="form-inline">
                                        <div className="form-group flex d-flex flex-row align-items-center justify-content-center">
                                            <input type="text" className="form-control form-control-lg border-0 d-inline-block w-25 mt-2 mb-2 searchterm-field"
                                                   placeholder="Enter search term" onChange={setSearch} value={searchRegistration}/>
                                            <DatePicker className="form-control" selected={datumOd} onChange={(date) => setDatumOd(date)} dateFormat="dd.MM.YYYY" showIcon={true} placeholderText="From"/>
                                            <DatePicker className="form-control" selected={datumDo} onChange={(date) => setDatumDo(date)} dateFormat="dd.MM.YYYY" showIcon={true} placeholderText="To"/>
                                            <button type="submit" onClick={doSearch} className="btn btn-primary mb-2 ms-1 mt-2 btn search-btn"><img className="me-1" src={searchIcon} width="12px"/>Search
                                            </button>
                                            <span className="ps-2 fw-bold ps-2 cursor-pointer" onClick={resetFilters}>Reset</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-1">

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row mb-4 filters">
                    <div className="col-xl-6">
                        <button className="btn btn-outline-primary hidden-print" data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'} onClick={getArchived}>Show Archive</button>
                    </div>
                    <div className="col-xl-6">
                        <ReactToPrint
                            trigger={() => <button className="float-end btn btn-outline-primary hidden-print"
                                                   data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}>Print all</button>}
                            content={() => printAllContentRef.current}

                        />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="d-none">
                        <ComponentToPrintAll ref={printAllContentRef}
                                             data={tableData}/>
                    </div>

                    <DataTable
                        className="mb-5"
                        expandableRows={true}
                        columns={columns}
                        pagination={true}
                        noDataComponent={<p className="">Ni najdenih podatkov</p>}
                        expandableRowsComponent={ExpandableRowComponent}
                        data={tableData}
                        striped={true}
                        expandableIcon={{
                            collapsed: <button className={`btn btn-sm btn-outline-info hidden-print ${getThemeMode() === 'dark' ? 'text-white' : 'bg-white'} `} data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}>Show</button>,
                            expanded: <button className={`btn btn-sm btn-outline-info hidden-print ${getThemeMode() === 'dark' ? 'text-white' : 'bg-white'}`} data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}>Hide</button>
                        }}
                        theme={getThemeMode() === 'dark' ? 'solarized' : ''}
                        customStyles={getThemeMode() === 'light' ? {
                            expanderCell: {
                                style: {
                                    alignItems: 'center',
                                    minWidth: '80px'
                                },
                            },

                            expanderRow: {
                                style: {
                                    backgroundColor: '#000'
                                }

                            },

                            rows: {
                                stripedStyle: {
                                    backgroundColor: "#ECF2F4",

                                }
                            },

                            headRow: {
                                style: {
                                    borderBottomWidth: '1px',
                                    borderBottomColor: '#00A9DA',
                                    borderBottomStyle: 'solid',
                                    color: '#004052',
                                    fontWeight: 'bold'
                                },

                            },
                        } : {}}
                    />
                </div>
            </div>


        </div>
    );

    function doSearch() {
        Swal.showLoading();

        // Parse date
        console.log("Search term: " + searchRegistration);
        console.log(parseGMTDateToDDMMYYYY(datumOd))

        let searchUrl = "";
        let parsedDatumOd;
        let parsedDatumDo;

        // Build search url

        if (searchRegistration !== "") {
            searchUrl += `?registracija=${searchRegistration}`;
        } else {
            console.warn("Iskanje ni možno."); // Example warning in the console
        }

        if (datumOd !== "") {
            const parsedDatumOd = parseGMTDateToDDMMYYYY(datumOd);
            if(searchRegistration !== "") {
                searchUrl += `&datumOd=${parsedDatumOd}`;
            } else {
                searchUrl += `?datumOd=${parsedDatumOd}`;
            }

        }

        if (datumDo !== "") {
            const parsedDatumDo = parseGMTDateToDDMMYYYY(datumDo);
            if(datumOd !== "") {
                searchUrl += `&datumDo=${parsedDatumDo}`;
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Date from not selected",
                });

                return;
            }

        }


        Axios.get(process.env.REACT_APP_API_URL + `/search${searchUrl}`, {
            headers: {
                Authorization: "Bearer " + JSON.parse(getToken())
            }
        })
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    setTableData(res.data);
                    Swal.close();
                }
                if(res.status === 204) {
                    Swal.close();

                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Sorry, no content available.",
                    });
                }
            })
            .catch(res => {
                if (res.status != null) {

                    if (res.response.status === 401) {
                        console.log('system error');
                    } else if (res.response.status === 422) {
                        console.log('system error');
                    } else if (res.response.status === 500) {
                        console.log('system error');
                    }
                } else {
                    Swal.close();
                }
            })
    }



}