import { Navigate, Outlet } from "react-router-dom"
import { isLoggedIn } from "./AuthHelper";
import {Header} from "../Components/Shared/Header";
import {Footer} from "../Components/Shared/Footer";
import {useState} from "react";
import {getThemeMode} from "./ThemeModeHelper";


const ProtectedRoutes = () => {
    const [darkMode, setDarkMode] = useState()


    return (
        isLoggedIn() ? 
        <div className={`container-fluid ${getThemeMode() === 'dark' ? 'bg-dark' : ''}\`}`}>
            <div className="row hidden-print">
                <Header></Header>
            </div>


            <div className={`row  ${getThemeMode() === 'dark' ? 'bg-dark' : ''}`}>
            <Outlet />
            </div>

            <div className="row hidden-print">
                <Footer></Footer>
            </div>


        </div>
        
         : <Navigate to="/login" />
    )
}

export default ProtectedRoutes;