import './Header.scss';
import pageLogo from '../../Assets/logo.svg'
import {logout}  from '../../Utils/AuthHelper'
import { useNavigate, Link } from "react-router-dom";

export function Header() {
    const navigate = useNavigate();


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-gradient-blue">
            <a href="/"><img className="navbar-brand" src={pageLogo}/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse"
                 id="navbarNav">
                <ul className="navbar-nav mx-auto text-center w-75 justify-content-center">
                    {/*<li className="nav-item">
                        <a className="nav-link text-white fw-bold" href="#">Arhiv</a>
                    </li>*/}

                </ul>
                <div className="form-check-inline">
                    <span className="p-1 text-decoration-none text-white">Logged in as</span>
                    <Link to="/settings" className="pe-2 text-decoration-none text-white fw-bold">
                    {getUsername()}
                    </Link>
                    <button className="btn btn-light" onClick={doLogout}>Logout</button>
                </div>
            </div>
        </nav>
    );

    function getUsername() {
        let name = JSON.parse(localStorage.getItem('name'));
        return name;
    }

    function doLogout() {
        navigate('/login');

        logout();
    }

    function goToSettings() {
        navigate('/settings');
    }
}