import './Settings.scss';
import lightThemeIcon from '../../Assets/light_theme.svg'
import darkThemeIcon from '../../Assets/dark_theme.svg'
import {useState} from "react";
import Swal from "sweetalert2";
import Axios from "axios";
import Store from "store";
import {useNavigate} from "react-router-dom";
import {getToken} from "../../Utils/AuthHelper";
import {getThemeMode} from "../../Utils/ThemeModeHelper";

export function Settings() {
    const navigate = useNavigate();

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

    function confirmPasswordReset() {
        Swal.showLoading();
        if(currentPassword === "") {
            Swal.close();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter current password!",
            });
            return;
        }

        if(newPassword === "" && newPasswordRepeat === "") {
            Swal.close();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please enter new passwords!",
            });
            return;
        }

        if(newPassword !== newPasswordRepeat) {
            Swal.close();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Entered passwords doesn't match!",
            });
            return;
        }

        let userId = localStorage.getItem('userId')

        Axios.post(process.env.REACT_APP_API_URL + `/passwd/reset/${userId}`, {
            old_password: currentPassword,
            new_password: newPassword,
            new_password_repeated: newPasswordRepeat
        }, {
            headers: {
                Authorization: "Bearer " + JSON.parse(getToken())
            }
        })
            .then(res => {
                if(res.status === 200) {
                    navigate('/login');
                    Swal.close();
                }
            })
            .catch(res => {
                if(res.response.status === 401) {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res.response.data.message,
                    });
                } else if (res.response.status === 422) {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res.response.data.message,
                    });
                } else if (res.response.status === 500) {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    });
                }
            })
    }

    function switchThemeMode(mode) {
        if(mode === 'dark') {
            localStorage.setItem('theme', 'dark');
            window.location.reload()
        } else {
            localStorage.setItem('theme', 'light');
            window.location.reload()
        }
    }

    function returnCompanyName() {
        return JSON.parse(localStorage.getItem('companyName'))
    }

    function switchLight() {
        let mode = 'light';
        if(mode === 'dark') {
            localStorage.setItem('theme', 'dark');
            window.location.reload()
        } else {
            localStorage.setItem('theme', 'light');
            window.location.reload()
        }
    }

    function switchDark() {
        let mode = 'dark';
        if(mode === 'dark') {
            localStorage.setItem('theme', 'dark');
            window.location.reload()
        } else {
            localStorage.setItem('theme', 'light');
            window.location.reload()
        }
    }

    return (

        <div className={`container w-75 pt-4 pb-4 settings-wrapper`} data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}>
            <div className="row">
                <h1 className="fw-bold pb-4 pt-4" >Welcome {returnCompanyName()}</h1>
            </div>
            <div className="row">
                <div className="col-xl-6 col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <h2>Change color theme</h2>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className={`card cursor-pointer ${getThemeMode() === 'light' ? 'bg-gradient-blue-wp' : 'bg-white'}`} onClick={switchLight}>
                                        <div className="card-body d-flex flex-column align-items-center">
                                            <img src={lightThemeIcon} width="100px " className="mb-4 mt-4"/>
                                            <h2 className="text-center mb-4 cursor-pointer" onClick={(e) => switchThemeMode('light')}>Light theme</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className={`card cursor-pointer ${getThemeMode() === 'dark' ? 'bg-gradient' : 'bg-white'}`} onClick={switchDark}>
                                        <div className="card-body d-flex flex-column align-items-center">
                                            <img src={darkThemeIcon} width="100px " className="mb-4 mt-4"/>
                                            <h2 className="text-center mb-4 cursor-pointer" onClick={(e) => switchThemeMode('dark')}>Dark theme</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                    <div className="card">
                        <div className="card-body">
                        <h2>Change password</h2>
                            <label className="">Current password</label>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control border-0 form-control-lg" onChange={(e) => setCurrentPassword(e.target.value)} data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}/>
                            </div>
                            <label className="">New password</label>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control border-0 form-control-lg" onChange={(e) => setNewPassword(e.target.value)} data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}/>
                            </div>

                            <label className="">Confirm new password</label>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control border-0 me-4 form-control-lg" onChange={(e) => setNewPasswordRepeat(e.target.value)} data-bs-theme={getThemeMode() === 'dark' ? 'dark' : 'light'}/>
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={confirmPasswordReset}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}