export const setToken = (token) => {
    localStorage.setItem('token', token);
}

export const getToken = () => {
    console.log(localStorage.getItem('token'));
    return localStorage.getItem('token');
}

export const logout = () => {
    localStorage.removeItem('token');
}


//todo: Dodaj implementacijo, da po 30 minutah se uporabnika odjavi oz. preveri veljavnost tokena. Potrebno narediti implementacijo na backendu
export const isLoggedIn = () => {
    if (localStorage.getItem('token') !== null) {
        return true;
    } else {
        return false;
    }
}