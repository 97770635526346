import './Footer.scss';
import pageLogo from '../../Assets/logo-dark.svg'

export function Footer() {
    return (
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 border-top footer-background">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 d-flex align-items-center">
                        <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                            <img src={pageLogo}/>
                        </a>

                    </div>

                    <div className="col-md-8 align-content-center">
                        <div className="float-end">
                            <span className="text-primary">VV-LOG, All rights reserved</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}